import { AuthManager } from "@mantu/erp-spa-auth0";

var authConfig = {
  VITE_APP_AUTH_DOMAIN: process.env.AUTH0_DOMAIN,
  VITE_APP_AUTH_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
};

const authManager = new AuthManager(authConfig);

export const initializeAuth = async (registerSpaApps) => {
  await authManager.initializePortal(registerSpaApps);
};

export const logout = async () => {
  return authManager.logout();
};
